import Content from "./CContent"

export default function PageCV () {
  return <Content className="cv">
    <div style={{margin: '0.5em'}} >
      <h2>CV</h2>
      <hr/>
      <ul>
        <li><h4>2021</h4></li>
        <p>Filmmusik</p>
        <ul>
          <li>
            <p><i>Being Seen Seeing</i>, en kortfilm av Majse Vilstrup</p>
          </li>
        </ul>
        <li>
          <h4>2020</h4>
        </li>
        <p>Filmmusik</p>
        <ul>
          <li>
            <p><i>Et drama for tre personer</i>, en kortfilm av Minni Mertens</p>
            <p>Panorama production</p>
          </li>
        </ul>
        <p>Ljuddesign</p>
        <ul>
          <li>
            <p><i>Listening to light inhaling</i>, av Elieli. Dans på Intonal i Malmö</p>
          </li>
        </ul>
        <li>
          <h4>2018</h4>
        </li>
        <ul>
          <li>
            <u>Kompositör, Stockholms stadsteater</u>
            <p><i>Bang</i>, av Magnus Lindman med Anna Takanen.</p>
            <p>Regi: Frida Röhl</p>
            <p>Kompositör SVT</p>
          </li>
          <li>
            <p><i>Innan jag hoppar</i>, en kortfilm av Gustav Ahlgren</p>
          </li>
        </ul>

        <li>
          <h4>2017</h4>
        </li>
        <u>Kompositör, Unga Klara Stockholm</u>
        <ul>
          <li>
            <p>Avsked, av Kristian Hallberg</p>
            <p>Regi: Elin Skärstrand</p>
            <p>Kompositör Sveriges Radio</p>
          </li>
          <li>
            <p>Nobeldramat, <i>En by när mörkret lagt sig</i></p>
            <p>Av Kazuo Ishiguro. Regi: Frida Röhl</p>
          </li>
        </ul>

        <li>
          <h4>2016</h4>
        </li>
        <u>Kompositör, Sveriges radio</u>
        <ul>
          <li>
            <p><i>PLINT</i>, av Kristian Hallberg</p>
          </li>
        </ul>
        <u>Kompositör, Barnradion</u>
        <ul>
          <li>
            <p><i>Flykt</i></p>
          </li>
        </ul>
        <u>Kompositör och musiker, Folkteatern Göteborg</u>
        <ul>
          <li>
            <p>Folkbokförarna, en elektro-musikal av Amina Hocine och Alejandro Leiva Wenger</p>
          </li>
        </ul>

        <li>
          <h4>2015</h4>
        </li>
        <u>Kompositör och musiker, Folkteatern Göteborg</u>
        <ul>
          <li>
            <p><i>Not based on a true story</i>, regi Frida Röhl</p>
          </li>
          <li>
            <p><i>Variation</i>, regi Frida Röhl</p>
          </li>
          <li>
            <p><i>Revisorn</i>, regi Frida Röhl</p>
          </li>
        </ul>
      </ul>
      <h3>Stipendier</h3>
      <ul>
        <li>
          <h4>2021</h4>
        </li>
        <p>KMH stipendium</p>
        <li>
          <h4>2019</h4>
        </li>
        <p>Fonogramstöd</p>
        <li>
          <h4>2016</h4>
        </li>
        <p>Fonogramstöd</p>
        <li>
          <h4>2015</h4>
        </li>
        <p>Konstnärsnämndens arbetsstipendium, halvårs</p>
        <li>
          <h4>2014</h4>
        </li>
        <p>Konstnärsnämndens stipendium för internationellt kulturutbyte</p>
        <p>Konstnärsnämndens arbetsstipendium, halvårs</p>

      </ul>
      <h3>Residens</h3>
      <ul>
        <li>
          <p>Ställbergs gruva juni 2023</p>
        </li>
        <ul>
          <li><i>ātamōn - a life unit</i>. <a href="https://www.stallbergsgruva.se/Amina-Hocine-atamon-a-life-unit-2023">Mer info</a></li>
        </ul>
        <li>
          <p>Skogen april 2023</p>
        </li>
        <ul>
          <li><i>ātamōn - a life unit</i>. <a href="https://www.skogen.pm/event/Yx3hhaGiDnTGJpeSw">Mer info</a></li>
        </ul>
        <li>
          <p>Skogen 2021</p>
        </li>
        <ul>
          <li>Arbete och framträdande med föreställningen <i>Curious Squiggly air</i> med Rasmus Persson</li>
        </ul>
        <li>
          <p>Ställbergs gruva 2021</p>
        </li>
        <ul>
          <li>Arbete med föreställningen <i>Curious Squiggly air</i> med Rasmus Persson</li>
        </ul>
      </ul>
    </div>
  </Content>
}
