import "./App.css";
import { NavLink, Routes, Route } from "react-router-dom";
import PageAudio from "./PageAudio";
import PageMistlur from "./PageMistlur";
import PageAbout from "./PageAbout";
import PageCV from "./PageCV";

import Footer from "./CFooter";

function Page () {
  return <><div className="content-wrap">
    </div>
    <Footer/>
    </>
}

function App() {
  return (
    <div className="App">
      <NavLink className="main-link" to="/">Amina Hocine </NavLink>
      <nav>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/mustlursorgeln">The Instrument</NavLink>
        <NavLink to="/works">Works</NavLink>
        <NavLink to="/listen">Listen</NavLink>
      </nav>
      <Routes>
        <Route path="/" element={<Page/>} />
        <Route path="/about" element={<PageAbout />} />
        <Route path="/mustlursorgeln" element={<PageMistlur />} />
        <Route path="/listen" element={<PageAudio />} />
        <Route path="/works" element={<PageCV />} />
      </Routes>
    </div>
  );
}

export default App;
