import Content from "./CContent"

import photo from "./images/aminahocine_module.jpg"

export default function PageAbout() {
  return <Content>
    <div className="float-over text-small">
      Amina Hocine is a composer and artist currently pursuing her masters in electroacoustic composition at the Royal College of Music in Stockholm. She has a background in theater and film music and has worked on productions at Folkteatern Gothenburg, Swedish national radio and television, Unga Klara, and Stadsteatern in Stockholm. Her music centers around narrative, timbre, and deep listening, and she is deeply inspired by spiritual science..
    </div>
    <img
      src={photo}
      alt="Amina"
    />
    </Content>
}
